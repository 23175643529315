import React from "react";

function ContactForm() {
	const onSend = () => {};
	return (
		<div>
			<button onClick={onSend}>Send Email</button>
		</div>
	);
}

export default ContactForm;
